.datepicker-dateList {
  border-top: 1px solid var(--primary);
  width: 440px;
  display: flex;
  margin: 2px 0 4px 40px;
}

.datepicker-date-day-Item{
  background:  #e6545b;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 3px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
}

.datepicker-button-previous-wrapper {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 10px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  /* color: #e6545b; */
}


.datepicker-strip {

  display: flex;
  /*align-items: center;*/
  flex-direction: column;
  margin-top: 4px;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  line-height: 1.5;
  font-family: sans-serif;
}

.date-day-Item-selected {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background:  #e6545b;
  color: #fff;

}

.date-day-item-disabled {
  display: none;
  width: 40px;
  height: 40px;
  background:  #fff;
  color: #fff;
  pointer-events: none;
  border: 1px solid #fff;
}

.datepicker-datelist-scrollable {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;
   margin: 8px 4px 2px 0;

}

.datepicker-datelist-scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}

.datepicker{
  display: flex;
  margin: 10px 0 0 4px;
  align-items: center;
  position: relative;
}

.datepicker-day-label{
  font-size: 10px;
  margin: 4px 0 0 0;
  text-align: center;
}

.scroll-head{
  display: none;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  text-align: center;
  height: 10px;
  font-size: 12px;
  background-color:  #e6545b;
  color: #fff;
}
.blank-space-div{
  border: 1px solid transparent;
  border-radius: 2px;
  text-align: center;
  height: 18px;
  font-size: 12px;

}

.datepicker-button-previous, .datepicker-button-next{
  border: none;
  text-decoration: none;
  background: #e6545b;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  flex-shrink: 0;
}


.button-previous{
  font-weight: bold;
  display: none;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  /* color: #e6545b; */
}

.datepicker-button-previous{
  transform: rotate(180deg);

}


.datepicker-month-label  {
  display: none;
  margin-left: 10px;
  font-size: 15px;
  background-color:  #e6545b;
  color: #fff;
  font-weight: bold;
}

.datepicker-date-label  {
  /* font-size: 12px; */
  margin-top: -3px;
}
.wrapper{
  display: flex;
  margin: 0 4px 0 0;
  position: relative;
}
@-webkit-keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: .9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: .9;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.ripple:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: currentColor;
  visibility: hidden;
  z-index: 2;
}
.ripple:not(:active):before {
  -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: visibility .9s step-end;
}
.ripple:active:before {
  visibility: visible;
}
